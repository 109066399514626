import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "sanitize.css/sanitize.css";
import "orion/lib/styles/orion.css";

import { HttpClientProvider } from "contexts/httpClient";
import history from "utils/history";
import store from "utils/store";
import FullPageLoader from "shared/FullPageLoader";
import ToastRenderer from "shared/ReduxToast";
import { ModalRenderer } from "shared/ReduxModal";
import UpdateMSPForm from "pages/Account/UpdateMSPForm/UpdateMSPForm";
import SuspendAction from "components/SuspendAction/SuspendActionModal";
import UpdatePermissionForm from "pages/Account/Summary/UpdatePermissionForm";
import { UPDATE_PERMISSIONS_FORM_KEY } from "pages/Account/Summary";

import App from "pages";
import * as serviceWorker from "./serviceWorker";

const formViews = {
  UPDATE_MSP_FORM: UpdateMSPForm,
  [UPDATE_PERMISSIONS_FORM_KEY]: UpdatePermissionForm,
};

const simpleViews = {
  SUSPEND_ACTION: SuspendAction,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <HttpClientProvider>
      <BrowserRouter history={history}>
        <QueryClientProvider client={queryClient}>
          <App />
          <ModalRenderer formViews={formViews} simpleViews={simpleViews} />
          <ToastRenderer />
          <FullPageLoader />
        </QueryClientProvider>
      </BrowserRouter>
    </HttpClientProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
