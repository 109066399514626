import React, { Fragment } from "react";
import { Page } from "orion";
import { Routes, Route, Navigate } from "react-router-dom";

import Navbar from "shared/Navbar";
import Accounts from "./Accounts/Accounts";
import Account from "./Account/Account";

const App = () => (
  <Fragment>
    <Navbar brandName="Managed Services Center Support Portal" />
    <Page>
      <Routes>
        <Route path="accounts" element={<Accounts />} />
        <Route path="accounts/:accountId" element={<Account />} />
        <Route path="/" element={<Navigate replace to="accounts" />} />
      </Routes>
    </Page>
  </Fragment>
);

export default App;
